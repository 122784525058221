<template>
  <div>
    <a-form
      class="simiot-nested-search-form"
      :form="form"
      @submit="handleSearch"
    >
      <a-row :gutter="24">
        <a-col :span="8" :xxl="6">
          <a-form-item label="">
            <a-input
              v-decorator="['iccid_or_phone_number_or_imsi', {
                normalize: this.$lodash.trim
              }]"
              placeholder="请输入ICCID或SIMID或手机号或IMSI"
              allow-clear
            />
          </a-form-item>
        </a-col>

        <a-col :span="8" :xxl="6" v-show="expand">
          <a-form-item label="运营商种类">
            <a-select
              v-decorator="['carrier_type']"
              allow-clear
              @change="handleCarrierTypeChange"
            >
              <a-select-option
                v-for="option in carrierTypeOptions"
                :key="option.value"
                :value="option.value"
              >
                {{ option.label }}
              </a-select-option>
            </a-select>
          </a-form-item>
        </a-col>

        <a-col :span="8" :xxl="6" v-show="expand">
          <a-form-item label="运营商账户">
            <a-select
              v-decorator="['carrier_account_id']"
              :loading="loadingCarrierAccountOptions"
              allow-clear
              @change="handleCarrierAccountChange"
            >
              <a-select-option
                v-for="option in carrierAccountOptions"
                :key="option.id"
                :value="option.id"
              >
                {{ option.name }}
              </a-select-option>
            </a-select>
          </a-form-item>
        </a-col>

        <a-col :span="8" :xxl="6" v-show="expand">
          <a-form-item label="运营商状态">
            <a-select
              v-decorator="['card_status']"
              :loading="loadingCardStatusOptions"
              allow-clear
            >
              <a-select-option
                v-for="option in cardStatusOptions"
                :key="option.value"
                :value="option.value"
              >
                {{ option.name }}
              </a-select-option>
            </a-select>
          </a-form-item>
        </a-col>

        <a-col :span="8" :xxl="6" v-show="expand">
          <a-form-item label="卡片材质">
            <a-select
              v-decorator="['card_type_id']"
              allow-clear
              :loading="loadingCardTypeOptions"
              @focus="fetchCardTypeOptions"
            >
              <a-select-option
                v-for="option in cardTypeOptions"
                :key="option.id"
                :value="option.id"
              >
                {{ option.name }}
              </a-select-option>
            </a-select>
          </a-form-item>
        </a-col>

        <a-col :span="8" :xxl="6" v-show="expand">
          <a-form-item label="超套停">
            <a-select
              v-decorator="['is_exceed_package_stop']"
              allow-clear
            >
              <a-select-option key="1" value="1">
                是
              </a-select-option>
              <a-select-option key="0" value="0">
                否
              </a-select-option>
            </a-select>
          </a-form-item>
        </a-col>

        <a-col :span="8" :xxl="6" v-show="expand">
          <a-form-item label="入库时间">
            <a-range-picker v-decorator="['imported_at']" />
          </a-form-item>
        </a-col>

        <a-col :span="8" :xxl="6" v-show="expand">
          <a-form-item label="运营商测试期">
            <a-range-picker v-decorator="['carrier_test_period_end_date']" />
          </a-form-item>
        </a-col>

        <a-col :span="8" :xxl="6" v-show="expand">
          <a-form-item label="运营商沉默期">
            <a-range-picker v-decorator="['carrier_silent_period_end_date']" />
          </a-form-item>
        </a-col>

        <a-col :span="8" :xxl="6" v-show="expand">
          <a-form-item label="运营商激活时间">
            <a-range-picker v-decorator="['carrier_activated_at']" />
          </a-form-item>
        </a-col>

        <a-col :span="8" :xxl="6">
          <a-form-item>
            <a-space>
              <a-button type="primary" html-type="submit">
                搜索
              </a-button>
              <a-button @click="handleReset">
                刷新
              </a-button>
              <a-button :style="{ fontSize: '12px', lineHeight: '32px' }" @click="toggle">
                更多条件
                <a-icon :type="expand ? 'up' : 'down'" />
              </a-button>
            </a-space>
          </a-form-item>
        </a-col>
      </a-row>
    </a-form>
  </div>
</template>
<script>
import { formatRangeBeginDate, formatRangeEndDate } from '@/utils/time'
import {
  findCarrierTypeOptions,
  findCarrierAccountOptions,
  findCarrierAccountCardStatusOptions,
  findCardTypeOptions
} from '@/api/common_options'

export default {
  name: 'SearchSimCardInventory',
  data() {
    return {
      expand: false,
      carrierTypeOptions: findCarrierTypeOptions(),
      carrierAccountOptions: [],
      cardStatusOptions: [],
      cardTypeOptions: [],
      loadingCarrierAccountOptions: false,
      loadingCardStatusOptions: false,
      loadingCardTypeOptions: false,
      form: this.$form.createForm(this, { name: 'sim_card_inventory_search' })
    }
  },
  methods: {
    toggle() {
      this.expand = !this.expand
    },

    // 加载卡片类型选项
    fetchCardTypeOptions() {
      if (this.cardTypeOptions.length === 0) {
        this.loadingCardTypeOptions = true
        findCardTypeOptions().then(res => {
          if (res.code === 0) {
            this.cardTypeOptions = res.data
            this.loadingCardTypeOptions = false
          }
        })
      }
    },

    // 运营商种类变更，加载运营商账户和运营商状态
    handleCarrierTypeChange(carrierType) {
      this.loadingCarrierAccountOptions = true
      // 清空选项
      this.carrierAccountOptions = []
      this.cardStatusOptions = []
      // 清空选项选中的值
      this.form.setFieldsValue({
        carrier_account_id: undefined,
        card_status: undefined
      })

      if (carrierType) {
        findCarrierAccountOptions(carrierType).then(res => {
          if (res.code === 0) {
            this.loadingCarrierAccountOptions = false
            this.carrierAccountOptions = res.data
          }
        })
      }
    },

    // 运营商账户变更，加载运营商状态
    handleCarrierAccountChange(carrierAccountId) {
      this.loadingCardStatusOptions = true
      // 清空选项
      this.cardStatusOptions = []
      // 清空选项选中的值
      this.form.setFieldsValue({
        card_status: undefined
      })

      if (carrierAccountId) {
        findCarrierAccountCardStatusOptions(carrierAccountId).then(res => {
          if (res.code === 0) {
            this.loadingCardStatusOptions = false
            this.cardStatusOptions = res.data
          }
        })
      }
    },

    handleSearch(e) {
      e.preventDefault()
      const fieldsValue = this.form.getFieldsValue()
      const values = {
        ...fieldsValue,
        import_time_begin: formatRangeBeginDate(fieldsValue.imported_at),
        import_time_end: formatRangeEndDate(fieldsValue.imported_at),
        carrier_test_period_date_begin: formatRangeBeginDate(fieldsValue.carrier_test_period_end_date),
        carrier_test_period_date_end: formatRangeEndDate(fieldsValue.carrier_test_period_end_date),
        carrier_silent_period_date_begin: formatRangeBeginDate(fieldsValue.carrier_silent_period_end_date),
        carrier_silent_period_date_end: formatRangeEndDate(fieldsValue.carrier_silent_period_end_date),
        carrier_activation_time_begin: formatRangeBeginDate(fieldsValue.carrier_activated_at),
        carrier_activation_time_end: formatRangeEndDate(fieldsValue.carrier_activated_at)
      }
      this.$emit('submit', values)
    },

    handleReset() {
      this.carrierAccountOptions = []
      this.cardStatusOptions = []
      this.cardTypeOptions = []
      this.form.resetFields()
      this.$emit('submit', {})
    }
  }
}
</script>
